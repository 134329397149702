/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import 'typeface-lato'
import React from 'react'
import * as PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import usePrevious from '../hooks/use-previous-value'
import nodePath from 'path'

import Header from './header/header'
import Footer from './footer'
import './layout.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font: 112.5%/1.45em;
    ${props => props.theme.media.sm`
      font-size: 100%;
    `};
    ${props => props.theme.media.xs`
      font-size: 80%;
    `};
  }
  body, a {
    color: ${p => p.theme.text.black.main};
  }
  a {
    text-decoration: none;
  }
`

const Layout = ({ children, location: { pathname } }) => {
  const [animated, setAnimated] = React.useState([])
  const previousRoute = usePrevious(pathname)

  React.useEffect(() => {
    previousRoute &&
      previousRoute !== pathname &&
      setAnimated(a => [...new Set([...a, previousRoute])])
  }, [previousRoute, pathname, setAnimated])

  const data = useStaticQuery(graphql`
    query RoutesQuery {
      allSitePage(
        filter: {
          internalComponentName: { regex: "/^((?!404|Index|Offline).)*$/" }
        }
      ) {
        edges {
          node {
            component
            internalComponentName
            path
          }
        }
      }
    }
  `)

  // TODO: review this shit code
  const routes = data.allSitePage.edges.map(({ node }) => {
    return {
      title: nodePath
        .basename(node.component)
        .slice(0, -3)
        .split('-')
        .join(' '),
      to: node.path,
    }
  })
  const sortedRoutes = [routes[0], routes[3], routes[2], routes[1]]

  const cloned = React.cloneElement(children, {
    animate: !animated.includes(pathname),
  })

  return (
    <>
      <GlobalStyle />
      <Header isLandingPage={pathname === '/'} routes={sortedRoutes} />
      <main>{cloned}</main>
      <Footer routes={sortedRoutes} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
