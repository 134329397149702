/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')
const Layout = require('./src/components/layout').default
const theme = require('./src/theme/theme').default
const styled = require('styled-components')
const ThemeProvider = styled.ThemeProvider
const BreakpointsContext = require('./src/context/breakpoints')
const BreakpointsProvider = BreakpointsContext.Provider

exports.wrapPageElement = ({ element, props }) => {
  return (
    <BreakpointsProvider>
      <ThemeProvider theme={theme}>
        <Layout {...props}>{element}</Layout>
      </ThemeProvider>
    </BreakpointsProvider>
  )
}
