import React from 'react'
import * as PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import FullScreenOverlay, {
  overlayPropTypes,
  overlayDefaultProps,
} from './full-screen-overlay'

const FullScreenModal = props => {
  const { color, trigger, children, open, onClose, ...rest } = props

  const [isOpen, setIsOpen] = React.useState(!trigger && open)
  const [background, setBackground] = React.useState(color)

  if (!isOpen && trigger)
    return React.cloneElement(trigger, {
      onClick: () => setIsOpen(o => !o),
    })

  const closeModal = () => {
    setIsOpen(false)
    setBackground(color) // reset background
    onClose && onClose()
  }

  const onEscPress = () => closeModal()

  if (!isOpen) return null

  return createPortal(
    <FullScreenOverlay
      color={background}
      closeModal={closeModal}
      onEscPress={onEscPress}
      {...rest}
    >
      {React.cloneElement(children, {
        setBackground: background => setBackground(background),
        background,
        closeModal,
      })}
    </FullScreenOverlay>,
    document.body,
  )
}

FullScreenModal.propTypes = {
  trigger: PropTypes.element,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  color: overlayPropTypes.color,
  children: overlayPropTypes.children,
  withHeader: PropTypes.bool,
}

FullScreenModal.defaultProps = overlayDefaultProps

export default FullScreenModal
