import React from 'react'

export default function useScroll({ once }) {
  const [ref, setRef] = React.useState(null)
  const [visible, setVisible] = React.useState(null)
  const observer = React.useRef()

  React.useEffect(() => {
    visible && once && observer.current.unobserve(ref)
  }, [visible, once])

  React.useEffect(() => {
    if (ref) {
      observer.current = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            setVisible(entry.intersectionRatio > 0)
          })
        },
        {
          threshold: 0.3,
        },
      )
      observer.current.observe(ref)
    }
    return () => observer.current && observer.current.unobserve(ref)
  }, [ref])

  return [visible, setRef, visible !== null]
}
