export const getFormValues = (e, options = {}) => {
  const { removeBlank } = options

  return Array.from(e.target.elements)
    .map(i => i.name)
    .reduce((agg, el) => {
      if (!el) return agg
      const domEl = e.target.elements.namedItem(el)
      let value = domEl[domEl.type === 'checkbox' ? 'checked' : 'value']
      if (domEl.type === 'file') {
        value = domEl.files
      }
      if (removeBlank && !value) return agg
      agg[el] = value
      return agg
    }, {})
}

export const hasAnyFile = e => {
  return Array.from(e.target.elements).find(i => i.type === 'file')
}
