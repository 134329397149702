import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const inputStyles = css`
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  background-color: transparent;
  width: 100%;
  font-size: 0.9rem;
  ${props => props.theme.media.sm`
    font-size: 1.4rem;
    padding: 0.2em 0;
  `};
  :disabled {
    opacity: 0.7;
  }
`
const StyledInput = styled.input`
  ${inputStyles}
`

const Input = ({ placeholder, ...props }) => (
  <StyledInput placeholder={placeholder} {...props} />
)

Input.propTypes = {
  placeholder: PropTypes.string,
}

export default Input
