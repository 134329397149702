import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import FullScreenModal from '../../full-screen-modal/full-screen-modal'
import HeaderLayout from '../header-layout'
import Display, { menuTriggerStyles } from './display'

const MenuTrigger = styled.div`
  ${menuTriggerStyles};
  background: ${p =>
    !p.isLandingPage ? p.theme.palette[p.color] : p.theme.text.white[200]};
  > div {
    background: ${p =>
      p.isLandingPage ? p.theme.palette[p.color] : '#ffffff'};
    :first-child {
      margin-bottom: 0.1em;
    }
  }
`

const SmHeader = ({ routes, isLandingPage, className }) => (
  <HeaderLayout
    whiteLogo={isLandingPage}
    logoProps={{ to: '/' }}
    action={
      <FullScreenModal
        color="primary"
        trigger={
          <MenuTrigger
            color={isLandingPage ? 'primary' : 'secondary'}
            isLandingPage={isLandingPage}
            className={className}
          >
            <div />
            <div />
          </MenuTrigger>
        }
      >
        <Display isLandingPage={isLandingPage} routes={routes} />
      </FullScreenModal>
    }
  />
)

SmHeader.propTypes = {
  isLandingPage: PropTypes.bool,
}

export default SmHeader
