import React from 'react'
import { getFormValues, hasAnyFile } from '../utils/utils'

export default function useForm({ onSubmit }) {
  const [submitting, setSubmitting] = React.useState(false)
  const [resetFileString, setResetFileString] = React.useState(+new Date())

  const handleSubmit = async e => {
    e.preventDefault()
    e.persist()
    const hasFile = hasAnyFile(e)
    setSubmitting(true)
    const body = hasFile
      ? new FormData(e.target)
      : new URLSearchParams(getFormValues(e, { removeBlank: true }))

    await fetch('/', { method: 'POST', body })

    e.target.reset()
    hasFile && setResetFileString(+new Date())
    setSubmitting(false)
    onSubmit && onSubmit()
  }

  return { handleSubmit, submitting, resetFileString }
}
