import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const buttonVariants = {
  raised: css`
    background: white;
    color: ${props => props.theme.palette[props.color]};
    &:hover:not(:disabled),
    &:focus {
      background: ${props => props.theme.utils.shade('#ffffff', -3)};
    }
  `,
  contained: css`
    background: ${props => props.theme.palette[props.color]};
    color: white;
    &:hover:not(:disabled),
    &:focus {
      background: ${props =>
        props.theme.utils.shade(props.theme.palette[props.color], -3)};
    }
  `,
}

const StyledButton = styled.button`
  ${props => buttonVariants[props.variant]};
  box-shadow: ${props => props.theme.shadows.button.main};
  border-radius: 5px;
  padding: 1.25em 4em;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  &:hover:not(:disabled) {
    box-shadow: ${props => props.theme.shadows.button.hover};
  }
  &:active {
    box-shadow: ${props => props.theme.shadows.button.active};
  }
  :disabled {
    opacity: 0.8;
  }
  transition: background 200ms ease-in-out;
`

const Button = ({ label, ...rest }) => {
  return <StyledButton {...rest}>{label}</StyledButton>
}

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['raised', 'contained']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  color: 'primary',
  variant: 'raised',
}

export default Button
