import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import TechPadLogo from '../images/tech-pad-logo'

const FooterContainer = styled.footer`
  padding: 90px 0;
  ${props => props.theme.media.sm`
    padding: 60px 0;
  `};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Inner = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `};
`

const LinksWrapper = styled.div`
  flex: 1;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  ${props => props.theme.media.xs`
    width: 100%;
    justify-content: space-around;
  `};
`

const FooterLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
  &:not(:last-child) {
    margin-right: 3vw;
  }
  &:hover {
    color: ${p => p.theme.utils.shade(p.theme.text.black.main, 160)};
  }
`

const Logo = styled(Link)`
  position: absolute;
  display: flex;
`

const TradeMark = styled.div`
  flex: none;
  color: ${p => p.theme.text.black[200]};
  font-size: 0.9rem;
  ${props => props.theme.media.sm`
    margin-top: 100px;
  `};
`

const Footer = ({ routes, ...props }) => (
  <FooterContainer {...props}>
    <Inner>
      <LinksWrapper>
        {routes.map(({ title, to }) => (
          <FooterLink to={to} key={to}>
            {title}
          </FooterLink>
        ))}
      </LinksWrapper>
      <TradeMark>© {new Date().getFullYear()}, Techpad</TradeMark>
    </Inner>
    <Logo to="/">
      <TechPadLogo width={180} height={48} />
    </Logo>
  </FooterContainer>
)

export default Footer
