import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import FileInput from './file'
import Input from './input'
import MultiSelect from './multi-select'
import Textarea from './textarea'

const FormInputContainer = styled.div`
  position: relative;
  padding: 15px 0;
  display: ${props => (props.visible ? 'block' : 'none')};
  ${props => props.theme.media.sm`
    padding: 10px 0;
  `};
`

const FormLabel = styled.label`
  font-size: 1.3rem;
  position: absolute;
  left: -170px;
  text-transform: uppercase;
  font-weight: bold;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  ${props => props.theme.media.sm`
    position: static;
    text-align: left;
    display: block;
    margin-bottom: 0.2rem;
  `};
`

const Required = styled.span`
  font-size: 1rem;
  font-weight: normal;
`

const FormInput = ({
  name,
  kind,
  label,
  disabled,
  visible,
  hidden,
  ...props
}) => (
  <FormInputContainer visible={visible && !hidden}>
    <FormLabel htmlFor={name} disabled={disabled}>
      {label} {props.required && <Required>&#42;</Required>}
    </FormLabel>
    {kind === 'input' && <Input name={name} disabled={disabled} {...props} />}
    {kind === 'textarea' && (
      <Textarea name={name} disabled={disabled} {...props} />
    )}
    {kind === 'file' && (
      <FileInput name={name} disabled={disabled} {...props} />
    )}
    {kind === 'multiselect' && (
      <MultiSelect name={name} disabled={disabled} {...props} />
    )}
  </FormInputContainer>
)

FormInput.propTypes = {
  kind: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

FormInput.defaultProps = {
  kind: 'input',
  visible: true,
}

export default FormInput
