import React from 'react'
import Button from '../ui/button'
import styled from 'styled-components'
import ArrowIcon from '../images/arrow'

const SuccessWrapper = styled.div`
  position: relative;
  top: 40vh;
`

const Title = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 3.4rem;
  font-weight: 800;
`

const Message = styled.p`
  font-size: 1rem;
  margin-bottom: 3rem;
`

const ButtonLabel = styled.div`
  display: flex;
  align-items: center;
  svg {
    transform: rotate(180deg);
    width: 15px;
    margin-right: 1rem;
    ${props => props.theme.media.md`
     width: 10px;
  `};
  }
`

const ApplicationSuccess = ({ onClose, color }) => {
  return (
    <SuccessWrapper>
      <Title>Thanks for reaching out!</Title>
      <Message>We will contact you as soon as possible</Message>
      <Button
        label={
          <ButtonLabel>
            <ArrowIcon />
            Go Back
          </ButtonLabel>
        }
        color={color}
        onClick={onClose}
      />
    </SuccessWrapper>
  )
}

export default ApplicationSuccess
