import React from 'react'

const TechPadLogo = ({ color, ...props }) => (
  <svg
    id="TechpadSvg"
    x="0px"
    y="0px"
    viewBox="397.7 307.9 593 177.1"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={color || '#273f5a'}
      d="M663.5 358.3L613 358.3 613 370.6 631.1 370.6 631.1 422.3 645.4 422.3 645.4 370.6 663.5 370.6z"
    />
    <path
      fill={color || '#273f5a'}
      d="M704.1 378.3c-4.7-4.1-10.5-6.1-17.3-6.1s-12.6 2.3-17.6 6.9-7.5 10.7-7.5 18.5 2.4 13.9 7.3 18.5 11.1 6.9 18.5 6.9c7.5 0 14-2.6 19.5-7.9l-7.7-8.3c-3.1 3.2-7.1 4.9-11.9 4.9-3 0-5.7-.9-8.1-2.6-2.4-1.7-3.8-3.9-4.2-6.6H711v-7.3c.2-7.2-2.2-12.8-6.9-16.9zm-28.4 14.4c.4-2.8 1.7-5 3.9-6.7 2.3-1.6 4.7-2.5 7.5-2.5 2.7 0 5 .8 6.9 2.4 1.9 1.6 3 3.8 3.3 6.8h-21.6zM742 410.8c-3.5 0-6.5-1.2-9.1-3.5s-3.9-5.6-3.9-9.7c0-4.2 1.3-7.4 3.9-9.8 2.6-2.3 5.7-3.5 9.3-3.5 2.3 0 4.5.5 6.8 1.6 2.3 1 4.2 2.5 5.8 4.4l7.1-9.3c-2.7-2.9-5.9-5-9.6-6.5-3.6-1.5-7.2-2.2-10.8-2.2-7.1 0-13.2 2.4-18.4 7.1s-7.7 10.9-7.7 18.4 2.5 13.6 7.6 18.3 11.3 7 18.7 7 14.2-3.3 20.6-9.9l-8.1-9.2c-3.5 4.5-7.7 6.8-12.2 6.8zM796.6 372.2c-5.2 0-9.8 2.1-14 6.3V349l-13.7 10.6v62.6h13.7v-26.5c0-3.8 1-6.8 3.1-8.7 2-2 4.4-3 7.2-3 5.6 0 8.3 3.8 8.3 11.3v26.9h13.7v-29.9c0-6.1-1.8-11-5.3-14.6-3.5-3.7-7.9-5.5-13-5.5zM861.5 372.2c-5.7 0-10.7 2.1-14.9 6.3V373h-13.7v70.8l13.7-10.6v-17.5c3.7 4.8 8.5 7.2 14.5 7.2s11.3-2.4 15.7-7.3c4.5-4.9 6.7-11 6.7-18.2 0-7.3-2.2-13.3-6.7-18.1-4.4-4.7-9.5-7.1-15.3-7.1zm4.7 35.3c-2.4 2.5-5.2 3.8-8.3 3.8-3.2 0-5.9-1.3-8.1-3.8-2.2-2.5-3.3-5.8-3.3-9.8s1.1-7.3 3.3-10 5-4 8.2-4 6 1.3 8.3 4 3.5 6 3.5 10-1.2 7.2-3.6 9.8zM912.6 372.2c-8.3 0-15.7 2.3-22.2 7l6.1 8.9c1.8-1.4 4.1-2.6 6.7-3.5 2.7-.9 5.2-1.4 7.6-1.4 5.6 0 8.3 2.6 8.3 7.9v.3h-10.3c-6.3 0-11.3 1.3-15 3.8s-5.5 6.3-5.5 11.3c0 5 1.8 9.1 5.3 12.1s7.9 4.5 13.1 4.5 9.6-2.2 13.1-6.7v6h12.9V391c0-6.5-1.9-11.2-5.7-14.2-3.5-3.1-8.4-4.6-14.4-4.6zm6.5 31.3c0 2.5-.9 4.5-2.8 6s-4 2.2-6.5 2.2-4.3-.5-5.6-1.4-2-2.3-2-4.1c0-3.4 2.7-5.1 8.2-5.1h8.6l.1 2.4zM977 359.6v18.9c-3.8-4.2-8.7-6.3-14.6-6.3-6 0-11.2 2.3-15.7 7s-6.7 10.7-6.7 18 2.3 13.5 6.9 18.4c4.6 4.9 9.8 7.4 15.6 7.4s10.7-2.4 14.5-7.2v6.5h13.7V349L977 359.6zm-3.2 47.9c-2.2 2.5-4.9 3.8-8.1 3.8-3.2 0-6-1.3-8.3-3.8-2.4-2.5-3.6-5.8-3.6-9.7 0-4 1.2-7.3 3.5-10s5.1-4 8.3-4 6 1.3 8.2 4 3.3 6 3.3 10c0 3.9-1.1 7.1-3.3 9.7z"
    />
    <path
      d="M512.7 307.9l-52.9 30.5 4.6 2.7 41.5 24c1.8-1.3 3.9-2.2 6.3-2.2 2.6 0 4.8 1 6.7 2.5l46.6-26.9-52.8-30.6z"
      fill={color || '#f4452e'}
    />
    <path
      d="M523.3 373.8c0 4.1-2.4 7.6-5.8 9.5v54.9l52.9-30.5v-61l-47.1 27.1z"
      fill={color || '#3e519e'}
    />
    <path
      fill={color || '#5791ee'}
      d="M445.5 341.3L397.7 313.7 397.7 374.8 445.6 402.4z"
    />
    <path
      d="M501.3 373.8c0-.1.1-.3.1-.4l-46.3-26.7v107.9L508 485V383.8c-3.9-1.6-6.7-5.5-6.7-10z"
      fill={color || '#5369d9'}
    />
  </svg>
)

export default TechPadLogo
