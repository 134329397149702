import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import TechPadLogo from '../../images/tech-pad-logo'

const HeaderLayoutWrapper = styled.div`
  margin: 0 auto;
  max-width: 93%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.header.lg.height}px;
  ${props => props.theme.media.sm`
    height: ${props => props.theme.header.sm.height}px;
  `};
  ${props => props.theme.media.xs`
    height: ${props => props.theme.header.xs.height}px;
  `};
`

const LogoWrapper = styled(({ whiteLogo, ...p }) =>
  p.to ? <Link {...p} /> : <button {...p} />,
)`
  flex: none;
  display: flex;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  svg {
    width: 190px;
    ${props => props.theme.media.sm`
      width: 160px;
    `};
    ${props => props.theme.media.xs`
      width: 130px;
    `};
    path {
      fill: ${p => p.whiteLogo && p.theme.text.white[200]};
    }
  }
`

const HeaderLayout = ({ logoProps, whiteLogo, action, links }) => {
  return (
    <HeaderLayoutWrapper>
      <LogoWrapper {...logoProps} whiteLogo={whiteLogo}>
        <TechPadLogo />
      </LogoWrapper>
      {links}
      {action}
    </HeaderLayoutWrapper>
  )
}

HeaderLayout.propTypes = {
  whiteLogo: PropTypes.bool,
}

export default HeaderLayout
