import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { buttonVariants } from './button'

const StyledButton = styled.button`
  ${props => buttonVariants[props.variant]};
  transition: transform 0.2s ease-out;
  border-radius: 50%;
  padding: 2em;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  > div {
    font-weight: bold;
    position: absolute;
    font-size: 1.7em;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      overflow: visible;
    }
  }
  :disabled {
    cursor: default;
    opacity: 0.7;
  }
`

const CircleButton = props => (
  <StyledButton {...props} type="button">
    <div>{props.children}</div>
  </StyledButton>
)

CircleButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['raised', 'contained']),
}

CircleButton.defaultProps = {
  color: 'primary',
  variant: 'raised',
}

export default CircleButton
