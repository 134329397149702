import { css } from 'styled-components'

export const breakpoint_sizes = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
}

export default Object.keys(breakpoint_sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoint_sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
