import React from 'react'
import styled, { css } from 'styled-components'
import UserStateContext from '../../context/breakpoints'
import LgHeader from './lg-header/lg-header'
import SmHeader from './sm-header/sm-header'

const StyledLgHeader = styled(LgHeader)`
  visibility: hidden;
`
const StyledSmHeader = styled(SmHeader)`
  visibility: hidden;
`

const landingPageStyles = css`
  position: absolute;
  width: 100%;
  z-index: 3;
  background: transparent;
`

const HeaderContainer = styled.header`
  ${props => props.isLandingPage && landingPageStyles};
  ${StyledLgHeader} {
    visibility: visible;
  }
  ${props => props.theme.media.sm`
    ${StyledLgHeader} {
      visibility: hidden;
    }
    ${StyledSmHeader} {
      visibility: visible;
    }
  `};
`

const Header = props => {
  const { xs, sm } = React.useContext(UserStateContext)
  const isDevice = xs || sm
  return (
    <HeaderContainer isLandingPage={props.isLandingPage}>
      {isDevice ? <StyledSmHeader {...props} /> : <StyledLgHeader {...props} />}
    </HeaderContainer>
  )
}

export default Header
