import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import FullScreenOverlay from '../components/full-screen-modal/full-screen-overlay'
import ApplicationSuccess from './application-success'
import ProjectForm from './project-form'
import QuestionForm from './question-form'

const texts = {
  question: {
    title: 'Question',
    subtitle: 'If you have any questions, please contact us',
    background: 'primary',
  },
  project: {
    title: 'New project',
    subtitle: "Let's work some magic together",
    background: 'secondary',
  },
}

const FormsContainer = styled.div`
  min-width: 500px;
  max-width: 33vw;
  ${props => props.theme.media.sm`
    width: 80%;
    min-width: unset;
    max-width: unset;
  `};
  margin: auto;
  text-align: center;
`

const Title = styled.h1`
  margin-bottom: 0.5vmax;
  margin-top: 3vmax;
  font-size: 3.5rem;
  text-transform: uppercase;
  ${props => props.theme.media.sm`
    font-size: 2.8rem;
    margin-top: 5vmax;
  `};
`

const Subtitle = styled.h2`
  font-size: 0.8rem;
`

const FormSwitcher = styled.div`
  border: 2px solid white;
  display: flex;
  cursor: pointer;
  margin: 2.5vmax 0;
  text-transform: uppercase;
  font-weight: bold;
  ${props => props.theme.media.sm`
    margin: 4vmax 0;
  `};
`

const FormItem = styled.div`
  background: ${props => props.active && '#ffffff'};
  padding: 0.8vmax 1vmax;
  flex: 1;
  color: ${props => props.active && props.theme.palette[props.background]};
  outline: none;
  font-size: 1rem;
  user-select: none;
  :focus {
    background: ${p =>
      p.theme.utils.shade(
        p.active ? '#ffffff' : p.theme.palette[p.background],
        p.active ? -3 : 10,
      )};
  }
  ${props => props.theme.media.sm`
    padding: 1.5vmax 1vmax;
  `};
`

const ContactForms = ({ setBackground, formName, closeModal }) => {
  const [form, setForm] = React.useState(formName)
  const { title, subtitle, background } = texts[form]
  const [successBg, setSuccessBg] = React.useState('')

  React.useEffect(() => {
    setBackground(background)
  }, [form])

  return (
    <FormsContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <FormSwitcher>
        <FormItem
          background={background}
          active={form === 'question'}
          onClick={() => setForm('question')}
          tabIndex={0}
          onKeyDown={e => [13, 32].includes(e.keyCode) && setForm('question')}
        >
          Question
        </FormItem>
        <FormItem
          background={background}
          active={form === 'project'}
          onClick={() => setForm('project')}
          tabIndex={0}
          onKeyDown={e => [13, 32].includes(e.keyCode) && setForm('project')}
        >
          Project
        </FormItem>
      </FormSwitcher>
      {form === 'question' && (
        <QuestionForm onSubmit={() => setSuccessBg('primary')} />
      )}
      {form === 'project' && (
        <ProjectForm onSubmit={() => setSuccessBg('secondary')} />
      )}
      {successBg && (
        <FullScreenOverlay color={successBg} withHeader={false}>
          <ApplicationSuccess color={successBg} onClose={closeModal} />
        </FullScreenOverlay>
      )}
    </FormsContainer>
  )
}

ContactForms.propTypes = {
  setBackground: PropTypes.func,
  formName: PropTypes.oneOf(['question', 'project']).isRequired,
  closeModal: PropTypes.func,
}

export default ContactForms
