import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ArrowButton, {
  arrowButtonPropTypes,
  arrowButtonDefaultProps,
} from './arrow-button'
import styled from 'styled-components'

const getColor = p => (p.gradient ? p.theme.text.black[100] : '#ffffff')

const Label = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${getColor}};
`

const StyledArrowButton = styled(ArrowButton)`
  font-size: 1.6em;
  transform: ${p => p.backwards && 'rotate(180deg)'};
  position: relative;
`

const StyledArrowLink = styled(({ backwards, gradient, ...p }) =>
  p.to ? <Link {...p} /> : <button {...p} />,
)`
  display: flex;
  flex-direction: ${p => (!p.backwards ? 'row' : 'row-reverse')};
  align-items: center;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
  border: none;
  background: transparent;
  ${StyledArrowButton} {
    margin-left: ${p => !p.backwards && '0.6em'};
    margin-right: ${p => p.backwards && '0.6em'};
  }
  outline: none;
  :hover,
  :focus {
    ${StyledArrowButton} {
      transform: ${p => p.backwards && 'rotate(180deg)'} translateX(0.2vw);
      opacity: 0.9;
    }
    ${Label} {
      color: ${p =>
        p.theme.utils.shade(getColor(p), getColor(p) === '#ffffff' ? -6 : 93)};
    }
  }
`

const ArrowLink = ({ to, label, className, onClick, ...props }) => (
  <StyledArrowLink
    to={to}
    className={className}
    color={props.color}
    onClick={onClick}
    {...props}
  >
    <Label color={props.color} {...props}>
      {label}
    </Label>
    <StyledArrowButton {...props} />
  </StyledArrowLink>
)

ArrowLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  backwards: PropTypes.bool,
  ...arrowButtonPropTypes,
}

ArrowLink.defaultProps = arrowButtonDefaultProps

export default ArrowLink
