import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowLink from '../../ui/arrow-link'
import HeaderLayout from '../header/header-layout'

const FOCUSABLE_SELECTOR =
  'a[href], input, select, textarea, button, object, embed, [tabindex], *[contenteditable]'
const FullScreenWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: ${props =>
    props.color ? props.theme.gradients[props.color].main : 'white'};
  z-index: 100;
  top: 0;
  left: 0;
  overflow: auto;
  color: ${props => props.color && 'white'};
`

const FullScreenContainer = styled.div``

const FullScreenOverlay = props => {
  const { children, closeModal, color, withHeader, onEscPress } = props

  React.useEffect(() => {
    const allModals = document.querySelectorAll('#modal')
    const parent =
      allModals[allModals.length - 2] || document.getElementById('___gatsby')

    const focusableElements = Array.from(
      parent.querySelectorAll(FOCUSABLE_SELECTOR),
    )

    if (parent.id === '___gatsby') {
      document.documentElement.style.overflow = 'hidden'
    }

    parent.setAttribute('aria-hidden', 'true')
    ;[parent, ...focusableElements].forEach(node => {
      node.setAttribute('tabindex', '-1')
    })

    return () => {
      if (parent.id === '___gatsby') {
        document.documentElement.style.overflow = null
      } else {
        parent.querySelectorAll(FOCUSABLE_SELECTOR)[0].focus()
      }

      parent.removeAttribute('aria-hidden')
      ;[parent, ...focusableElements].forEach(node => {
        node.removeAttribute('tabindex')
      })
    }
  }, [])

  const handleKeyDown = e => {
    e.stopPropagation()
    e.keyCode === 27 && onEscPress && onEscPress()
  }

  return (
    <FullScreenWrapper
      color={color}
      id="modal"
      role="dialog"
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {withHeader && (
        <HeaderLayout
          whiteLogo
          action={
            <ArrowLink
              label="go back"
              color={color}
              backwards
              onClick={closeModal}
            />
          }
        />
      )}
      <FullScreenContainer>{children}</FullScreenContainer>
    </FullScreenWrapper>
  )
}

export const overlayPropTypes = {
  children: PropTypes.element,
  color: PropTypes.oneOf(['primary', 'secondary']),
  closeModal: PropTypes.func.isRequired,
  onEscPress: PropTypes.func.isRequired,
  withHeader: PropTypes.bool,
}
export const overlayDefaultProps = {}

FullScreenOverlay.propTypes = overlayPropTypes
FullScreenOverlay.defaultProps = overlayDefaultProps

export default FullScreenOverlay
