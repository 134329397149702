import React from 'react'
import * as PropTypes from 'prop-types'
import useScroll from '../hooks/use-scroll'

const Animate = ({ children, once, animate, noSSR, ...rest }) => {
  const [isBrowser, setIsBrowser] = React.useState(false)

  React.useEffect(() => {
    setIsBrowser(true)
  }, [])

  const [visible, setRef, mounted] = useScroll({ once })

  if (!isBrowser)
    return children({
      ...(noSSR && {
        css: `
          visibility: hidden;
        `,
      }),
    })

  return children(
    animate
      ? {
          ref: setRef,
          css: `
  animation-delay: ${rest.delay};
  animation-duration: ${rest.duration};
  animation-fill-mode: ${rest.fill};
  animation-iteration-count: ${rest.count};
  animation-timing-function: ${rest.timing};
  animation-play-state: ${visible ? 'running' : 'paused'};
  visibility: ${mounted ? 'visible' : 'hidden'};
`,
        }
      : {},
  )
}

Animate.defaultProps = {
  once: true,
  duration: '400ms',
  delay: '0',
  fill: 'both',
  timing: 'ease-in-out',
  count: 1,
}

Animate.propTypes = {
  once: PropTypes.bool,
  duration: PropTypes.string,
  delay: PropTypes.string,
  count: PropTypes.any,
  timing: PropTypes.string,
  fill: PropTypes.string,
}

export default Animate
