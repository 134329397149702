import React from 'react'

const Arrow = props => (
  <svg x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" {...props}>
    <path
      fill="currentColor"
      d="M56 580h668L512 792c-12 12-16 24-16 40s4 28 16 40l32 32c12 12 24 16 40 16s28-4 40-16l360-360c12-12 16-24 16-40s-4-28-16-40L624 104c-12-12-24-16-40-16s-28 4-40 16l-32 32c-12 12-16 24-16 40s4 28 16 36l212 212H56c-32-4-56 24-56 56v48c0 28 28 52 56 52z"
    />
  </svg>
)

export default Arrow
