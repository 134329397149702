import React, { forwardRef } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowIcon from '../images/arrow'

const StyledButton = styled.div`
  transition: transform 0.2s ease-out;
  background: ${props =>
    props.gradient ? props.theme.gradients[props.color].main : 'white'};
  color: ${props => {
    if (props.gradient) return 'white'
    return props.theme.palette[props.color]
  }};
  border-radius: 50%;
  padding: 1.2em;
  ${props => props.theme.media.xs`
    padding: 1em;
  `};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    position: absolute;
    fill: currentColor;
  }
`

const ArrowButton = forwardRef((props, ref) => (
  <StyledButton {...props} ref={ref}>
    <ArrowIcon height="0.5em" width="0.5em" />
  </StyledButton>
))

export const arrowButtonPropTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  gradient: PropTypes.bool,
}

export const arrowButtonDefaultProps = {
  color: 'primary',
}

ArrowButton.propTypes = arrowButtonPropTypes
ArrowButton.defaultProps = arrowButtonDefaultProps

export default ArrowButton
