import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import CheckMarkIcon from '../images/checkmark'

const StyledMultiSelect = styled.div`
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
`

const Option = styled.div`
  border: 1px solid white;
  cursor: pointer;
  padding: 1.1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.8rem;
  user-select: none;
  opacity: ${p => (p.selected ? 1 : 0.7)};
  background: ${p => (p.selected ? 'white' : 'transparent')};
  color: ${p => p.selected && p.theme.palette.secondary};
  outline: none;
  :focus,
  :hover {
    opacity: 1;
  }
  transition: background 200ms ease-in-out;
  svg {
    position: absolute;
    right: -24px;
    opacity: ${p => (p.selected ? 1 : 0)};
    transition: opacity 200ms ease-out;
  }
  ${props => props.theme.media.sm`
    font-size: 1rem;
  `};
`

const InnerOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const MultiSelect = ({ options, name, ...rest }) => {
  const [value, setValue] = React.useState([])
  const toggle = service => {
    if (value.includes(service)) {
      setValue(v => v.filter(i => i !== service))
    } else {
      setValue(v => [...v, service])
    }
  }

  return (
    <StyledMultiSelect options={options} {...rest}>
      {options.map(option => (
        <Option
          key={option}
          selected={value.includes(option)}
          onClick={() => toggle(option)}
          tabIndex={0}
          onKeyDown={e => [13, 32].includes(e.keyCode) && toggle(option)}
        >
          <InnerOption>
            {option}
            <CheckMarkIcon height={24} width={24} />
          </InnerOption>
        </Option>
      ))}
      <input
        name={name}
        id={name}
        value={value.join(', ')}
        onChange={() => {}}
        tabIndex={-1}
        {...rest}
      />
    </StyledMultiSelect>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default MultiSelect
