import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import useForm from '../hooks/use-form'
import Button from '../ui/button'
import FormInput from '../ui/form-input'

const MAX_FILES = 5

const options = [
  'Blockchain development',
  'Product development',
  'Web development',
  'Mobile development',
  'UI/UX design',
  'Staff augmentation',
  'General inquiries',
]

const StyledMultiSelect = styled(FormInput)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 15px;
  ${options.reduce((agg, o, i) => {
    return agg + `> *:nth-child(${i + 1}) {grid-area: o${i + 1};}`
  }, '')};
  grid-template-areas:
    'o1 o1 o1 o2 o2 o2'
    'o3 o3 o4 o4 o5 o5'
    'o6 o6 o6 o7 o7 o7';
  ${props => props.theme.media.sm`
    grid-gap: 10px;
    grid-template-rows: none;
    grid-template-columns: none;
    grid-template-areas:
      'o1'
      'o2'
      'o3'
      'o4'
      'o5'
      'o6'
      'o7';
  `};
`

const SubmitButton = styled(Button)`
  margin-top: 2vmax;
`

const ProjectForm = ({ onSubmit }) => {
  const [currentFileIndex, setCurrentFileIndex] = React.useState(0)
  const { handleSubmit, submitting, resetFileString } = useForm({
    onSubmit: () => {
      onSubmit()
      setCurrentFileIndex(0)
    },
  })

  const displayNewFileInput = i =>
    setCurrentFileIndex(currentIndex => {
      if (currentIndex >= i + 1) return currentIndex
      return i + 1
    })

  return (
    <form onSubmit={handleSubmit} name="Project-3" data-netlify="true">
      <input type="hidden" name="form-name" value="Project-3" />
      <p style={{ display: 'none' }}>
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <FormInput
        name="name"
        placeholder="Your name"
        label="name"
        autoFocus
        disabled={submitting}
        required
      />
      <FormInput
        name="email"
        placeholder="Your email"
        label="email"
        type="email"
        disabled={submitting}
        required
      />
      <FormInput
        name="company"
        placeholder="Your company"
        label="company"
        disabled={submitting}
      />
      <StyledMultiSelect
        name="services"
        kind="multiselect"
        options={options}
        label="services"
        disabled={submitting}
        required
      />
      <FormInput
        name="message"
        placeholder="Explain in some detail what your project is about. Information on budgets, milestones and potential constraints would be very useful in kickstarting a productive conversation..."
        kind="textarea"
        disabled={submitting}
        label="message"
        required
      />
      {Array(MAX_FILES)
        .fill(null)
        .map((_, i) => (
          <Fragment key={i}>
            <FormInput
              key={resetFileString}
              onChange={() => displayNewFileInput(i)}
              visible={i <= currentFileIndex}
              name={`document_${i + 1}`}
              placeholder="Attach any file that might help get to know your project better (mock-ups, presentations, documents etc.)"
              kind="file"
              {...(i === 0 && { label: 'documents' })}
              disabled={submitting}
              color="secondary"
            />
          </Fragment>
        ))}
      <SubmitButton
        color="secondary"
        label={submitting ? 'Uploading...' : 'Send'}
        type="submit"
        disabled={submitting}
      />
    </form>
  )
}

ProjectForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ProjectForm
