import React from 'react'
import useBreakpoints from '../hooks/use-breakpoints'

const UserStateContext = React.createContext('')

export const Provider = ({ children }) => {
  const size = useBreakpoints()
  return (
    <UserStateContext.Provider value={size}>
      {children}
    </UserStateContext.Provider>
  )
}

export default UserStateContext
