import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import useForm from '../hooks/use-form'
import Button from '../ui/button'
import FormInput from '../ui/form-input'

const SubmitButton = styled(Button)`
  margin-top: 2vmax;
`

const QuestionForm = ({ onSubmit }) => {
  const { handleSubmit, submitting } = useForm({ onSubmit })

  return (
    <form onSubmit={handleSubmit} name="Questions-2" data-netlify="true">
      <input type="hidden" name="form-name" value="Questions-2" />
      <div style={{ display: 'none' }}>
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </div>
      <FormInput
        name="name"
        placeholder="Your name"
        label="name"
        autoFocus
        disabled={submitting}
        required
      />
      <FormInput
        name="email"
        type="email"
        placeholder="Your email"
        label="email"
        disabled={submitting}
        required
      />
      <FormInput
        name="question"
        placeholder="How can we help you?"
        kind="textarea"
        disabled={submitting}
        label="question"
        required
      />
      <SubmitButton
        color="primary"
        label={submitting ? 'Uploading...' : 'Send'}
        disabled={submitting}
        type="submit"
      />
    </form>
  )
}

QuestionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default QuestionForm
