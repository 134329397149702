import React from 'react'
import styled from 'styled-components'
import { inputStyles } from './input'

const TextArea = styled.textarea`
  ${() => inputStyles};
  min-height: 120px;
`

const Textarea = props => <TextArea {...props} />

export default Textarea
