import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import CircleButton from './circle-button'
import CheckMarkIcon from '../images/checkmark'

const Placeholder = styled.div`
  color: white;
  opacity: ${props => (props.disabled ? 0.4 : 0.6)};
  font-size: 0.7rem;
  line-height: 1.5;
  width: 80%;
  text-align: left;
  ${props => props.theme.media.sm`
    font-size: 1rem;
  `};
`

const FileInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  align-items: center;
  font-size: 0.8rem;
  :hover {
    ${Placeholder} {
      opacity: ${props => !props.disabled && 0.8};
    }
  }
`

const StyledInput = styled.input`
  display: none;
`

const FileInput = ({ placeholder, color, onChange, disabled, ...props }) => {
  const inputRef = React.useRef(null)
  const [value, setValue] = React.useState(null)

  const handleChange = e => {
    if (!e.target.files.length) return
    setValue(e.target.files[0].name)
    if (onChange) onChange(e.target.files[0])
  }

  return (
    <FileInputWrapper
      onClick={() => inputRef.current.click()}
      disabled={disabled}
    >
      <StyledInput
        type="file"
        onChange={handleChange}
        ref={inputRef}
        disabled={disabled}
        {...props}
      />
      <Placeholder disabled={disabled}>{value || placeholder}</Placeholder>
      <CircleButton color={color} disabled={disabled}>
        {!value ? '+' : <CheckMarkIcon height="1.2em" width="1.2em" />}
      </CircleButton>
    </FileInputWrapper>
  )
}

FileInput.propTypes = {
  placeholder: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
}

export default FileInput
