import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ContactForms from '../../../forms/contact-forms'
import Button from '../../../ui/button'
import FullScreenModal from '../../full-screen-modal/full-screen-modal'
import HeaderLayout from '../header-layout'

const LinksWrapper = styled.div`
  flex: 1;
  font-size: calc(7px + 0.5vw);
  font-weight: bold;
  display: flex;
  justify-content: center;
  &,
  & a {
    color: ${p => p.isLandingPage && p.theme.text.white.main};
    padding: 1rem 2.7rem;
    ${p => p.theme.media.md`
      padding: 1rem 1.4rem;
    `};
  }
`

const HeadingLink = styled(({ isLandingPage, ...p }) => <Link {...p} />)`
  text-decoration: none;
  &.active {
    color: ${props => props.theme.palette.secondary};
  }
  &::first-letter {
    text-transform: capitalize;
  }
  &:hover:not(.active) {
    color: ${p =>
      p.theme.utils.shade(
        p.isLandingPage ? '#ffffff' : p.theme.text.black.main,
        p.isLandingPage ? -16 : 160,
      )};
  }
`

const Separator = styled.div`
  margin: 0 1rem;
  color: ${p => p.theme.divider.white};
  font-weight: 200;
  padding: 1rem 0;
  cursor: default;
`

const ButtonWrapper = styled.div`
  flex: none;
`

const LgHeader = ({ routes, isLandingPage, className }) => (
  <HeaderLayout
    logoProps={{ to: '/' }}
    whiteLogo={isLandingPage}
    action={
      <FullScreenModal
        withHeader
        color="secondary"
        trigger={
          <ButtonWrapper className={className}>
            <Button
              {...(isLandingPage || { variant: 'contained' })}
              color="secondary"
              label="Hire us"
            />
          </ButtonWrapper>
        }
      >
        <ContactForms formName="project" />
      </FullScreenModal>
    }
    links={
      <LinksWrapper isLandingPage={isLandingPage} className={className}>
        {routes.map(({ title, to }, i) => (
          <Fragment key={to}>
            <HeadingLink
              to={to}
              activeClassName="active"
              isLandingPage={isLandingPage}
            >
              {title}
            </HeadingLink>
            {routes.length - 1 !== i && <Separator>|</Separator>}
          </Fragment>
        ))}
      </LinksWrapper>
    }
  />
)

LgHeader.propTypes = {
  isLandingPage: PropTypes.bool,
}

export default LgHeader
