import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import ContactForms from '../../../forms/contact-forms'
import Button from '../../../ui/button'
import Animate from '../../animate'
import FullScreenModal from '../../full-screen-modal/full-screen-modal'
import HeaderLayout from '../header-layout'

export const menuTriggerStyles = css`
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    height: 2px;
    width: 0.8em;
  }
`

const DeviceNav = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const HeaderWrapper = styled.div`
  flex: none;
`

const Body = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  button {
    height: 15vh;
    width: 100%;
    border-radius: 0;
  }
  flex: none;
  font-size: 2rem;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 3rem;
  height: 50%;
`

const HeadingWrapper = styled.div`
  ${p => p.css};
  animation-name: ${p => p.theme.animations.listItems};
  opacity: 0;
  &::first-letter {
    text-transform: capitalize;
  }
`

const HeadingLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.utils.shade(p.theme.text.white[200], -25)};
  font-size: 3.2rem;
  font-weight: 500;
  &.active {
    color: ${p => p.theme.text.white.main};
    font-weight: bolder;
  }
  &:hover:not(.active) {
    color: ${p => p.theme.utils.shade(p.theme.text.white[200], 160)};
  }
`

const CloseTrigger = styled.div`
  ${menuTriggerStyles};
  background: ${p => p.theme.text.white[200]};
  > div {
    position: absolute;
    transform: rotate(45deg);
    background: ${p => p.theme.palette.primary};
    :first-child {
      transform: rotate(135deg);
    }
  }
`

const Display = ({ routes, closeModal }) => {
  const mainRoute = { to: '/', title: 'main' }
  return (
    <DeviceNav>
      <HeaderWrapper>
        <HeaderLayout
          whiteLogo
          logoProps={{ to: mainRoute.to, onClick: closeModal }}
          action={
            <CloseTrigger onClick={closeModal}>
              <div />
              <div />
            </CloseTrigger>
          }
        />
      </HeaderWrapper>
      <Body>
        <LinksWrapper>
          {[mainRoute, ...routes].map(({ title, to }, i) => (
            <Animate
              delay={`${100 + i * 50}ms`}
              duration="200ms"
              animate
              key={to}
            >
              {props => (
                <HeadingWrapper {...props}>
                  <HeadingLink
                    to={to}
                    activeClassName="active"
                    onClick={closeModal}
                  >
                    {title}
                  </HeadingLink>
                </HeadingWrapper>
              )}
            </Animate>
          ))}
        </LinksWrapper>
      </Body>
      <ButtonWrapper>
        <FullScreenModal
          withHeader
          color="secondary"
          trigger={<Button color="secondary" label="Hire us" />}
        >
          <ContactForms formName="project" />
        </FullScreenModal>
      </ButtonWrapper>
    </DeviceNav>
  )
}

export default Display
