import { keyframes } from 'styled-components'
import media from './breakpoints'
import { shadeColor } from './colors'
const primary = '#5c55e4'
const secondary = '#f4452e'

const theme = {
  media,
  palette: {
    primary,
    secondary,
  },
  header: {
    lg: {
      height: 100,
    },
    sm: {
      height: 85,
    },
    xs: {
      height: 70,
    },
  },
  footer: {
    height: 140,
  },
  text: {
    black: {
      main: '#2B2B2B', // 'rgba(43, 43, 43)'
      100: '#3A3A3A', // 'rgba(58, 58, 58)'
      200: '#787878', // 'rgba(120, 120, 120)'
    },
    white: {
      main: '#ffffff',
      100: '#ebebf9',
      200: '#fbfbff',
    },
  },
  divider: {
    black: '',
    white: '#b5b6de',
  },
  gradients: {
    primary: {
      main: `linear-gradient(45deg, ${primary} 0%, #576ee3 45%, #5791ee 100%)`,
      hovered: `linear-gradient(-135deg, ${primary} 0%, #576ee3 45%, #5791ee 100%)`,
    },
    secondary: {
      main: secondary,
      hovered: shadeColor(secondary, 20),
    },
  },
  shadows: {
    button: {
      main: '0 4px 10.68px 1.32px rgba(1, 1, 1, 0.1)',
      hover: '0 4px 10.68px 1.32px rgba(1, 1, 1, 0.05)',
      active: 'none',
    },
  },
  backgrounds: {
    button: {
      hover: '#fafafa',
    },
  },
  utils: {
    shade: shadeColor,
  },
  border: {
    main: 'rgba(128, 128, 128, 0.2)',
    line: 'rgb(237, 237, 237)',
  },
  animations: {
    network: (ratio = 1) => keyframes`
      0% {
        transform: rotate3d(0, 0, 0, ${9 * ratio}deg);
      }
      70% {
        transform: rotate3d(30, 1, 1, ${9 * ratio}deg);
      }
      100% {
        transform: rotate3d(0, 0, 0, ${9 * ratio}deg);
      }
    `,
    mainIllustration: keyframes`
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    `,
    circle: keyframes`
        from {
            transform: rotate(-45deg);
            opacity: 0;
        }
        to {
            transform: rotate(0);
            opacity: 0.02;
        }
    `,
    polygon: keyframes`
      from {
        transform: rotate(0);
         opacity: 0;
      }
      to {
        transform: rotate(28deg);
         opacity: 0.1;
      }
    `,
    landingPageText: keyframes`
      0% {
        transform: translateY(5%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    `,
    landingPageTitle: keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `,
    innerPageTitle: keyframes`
      0% {
        transform: scale(0.9);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    `,
    listItems: keyframes`
      0% {
        transform: translateX(-5%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    `,
    arrowDown: keyframes`
      0% {
        transform: translateY(-10px) rotate(90deg);
        opacity: 0;
      }
       50% {
      transform: translateY(-3px)  rotate(90deg);
        opacity: 0.8;
      }
      100% {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
      }
    `,
    scrollText: keyframes`
      from {
          transform: rotate(270deg) translateX(300px);
          opacity: 0;
      }
      to {
          transform: rotate(270deg) translateX(100px);
          opacity: 1;
      }
      `,
    products: (ratio = 1) => keyframes`
      0% {
        transform: translateY(-${1 * ratio}%);
      }
       50% {
      transform: translateY(${2 * ratio}%);
      }
      100% {
        transform: translateY(-${1 * ratio}%);
      }
      `,
    crypto: (ratio = 1) => keyframes`
      0% {
        transform: translateY(-${1.5 * ratio}%) rotateX(${6 * ratio}deg);
      }
       50% {
      transform: translateY(${3 * ratio}%) rotateX(-${12 * ratio}deg);
      }
      100% {
       transform: translateY(-${1.5 * ratio}%) rotateX(${6 * ratio}deg);
      }
      `,
    leaf: (ratio = 1) => keyframes`
      from {
          transform: translateY(${ratio * 4}%);
          opacity: 0;
      }
      to {
          transform: translateY(0%);
          opacity: 1;
      }
      `,
    scaleIn: keyframes`
      from {
          transform: scale(0.5);
          opacity: 0;
      }
      to {
          transform: scale(1);
          opacity: 1;
      }
      `,
    fadeIn: keyframes`
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
      `,
  },
}

export default theme
