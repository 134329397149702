import React from 'react'
import { breakpoint_sizes } from '../theme/breakpoints'

const breakpointKeys = Object.keys(breakpoint_sizes)
const INIT = [...breakpointKeys, 'xl'].reduce((agg, el) => {
  agg[el] = false
  return agg
}, {})

export default function useBreakpoints() {
  const [breakpoints, setBreakpoints] = React.useState(() => INIT)

  const getWidth = () => window.innerWidth

  React.useLayoutEffect(() => {
    const handleResize = () => {
      const match =
        breakpointKeys.find(key => {
          return getWidth() <= breakpoint_sizes[key] ? key : false
        }) || 'xl'
      setBreakpoints(b => {
        if (b[match]) return b
        return {
          ...INIT,
          [match]: true,
        }
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setBreakpoints, getWidth])

  return breakpoints
}
